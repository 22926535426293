<!--
File: BudgetEnterForm.vue
Description: form for adding/editing a single budget line.
-->
<template>
  <form>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <RegionsDropdown :class="[{ 'md-valid': !errors.has('fk_region') }, { 'md-error': errors.has('fk_region') }]"
          :label="$t('road_network.region')" v-model="fk_region" data-vv-name="fk_region"
          v-validate="modelValidations.fk_region" :initial_value="fk_region" :is_required="true" required
          :disabled="approved == 1" />

        <md-field :class="[{ 'md-valid': !errors.has('year') }, { 'md-error': errors.has('year') }]">
          <label>{{ $t('budget.year') }}</label>
          <md-input v-model="year" type="number" data-vv-name="year" v-validate="modelValidations.year"
            :min="modelValidations.year.min_value" :max="modelValidations.year.max_value" required
            :disabled="approved == 1" />
        </md-field>

        <BaseDropdown :class="[{ 'md-valid': !errors.has('fk_work_category') }, { 'md-error': errors.has('fk_work_category') }]"
          :label="$t('label.category')" v-model="fk_work_category" :items="filteredCategories"
          data-vv-name="fk_work_category" v-validate="modelValidations.fk_work_category" :displayField="'description'"
          :valueField="'work_category_id'" required :disabled="approved == 1" />

        <FloatInput :class="[{ 'md-valid': !errors.has('amount_kgs') }, { 'md-error': errors.has('amount_kgs') }]"
          :label="$t('budget.amount')" v-model="amount_kgs" data-vv-name="amount_kgs"
          v-validate="modelValidations.amount_kgs" :min="modelValidations.amount_kgs.min_value" :step="1000" required
          :disabled="approved == 1" />
      </div>
    </div>

    <span v-if="errors.count() > 0" class="md-error">
      {{ $t('messages.errors_count', { count: errors.count() }) }}
    </span>
    <div class="md-layout-item md-size-100 text-right">
      <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
        {{ $t('buttons.save') }}
      </md-button>
      <md-button class="md-accent" @click.stop.prevent="onModalClose">
        {{ $t('buttons.close') }}
      </md-button>
    </div>
  </form>
</template>
<script>
  import RegionsDropdown from '@/pages/Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import FloatInput from '@/pages/Components/FloatInput.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onModalClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'budget-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        fk_region: 0,
        year: new Date().getFullYear(),
        fk_work_category: null,

        exchange_rate: 89,
        amount_kgs: 0,
        amount_usd: 0,
        version: 0,
        approved: 0,
        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          year: { required: true, numeric: true, min_value: 2021, max_value: 2045 },
          fk_work_category: { required: true, numeric: true, min_value: 1 },
          amount_kgs: { required: true, numeric: true, min_value: 1000 },
        }
      }
    },

    props: {
      budget: { default: {}, type: Object },
    },

    components: {
      RegionsDropdown,
      BaseDropdown,
      FloatInput
    },

    mounted() {
      if (this.budget) {
        this.fk_region = this.budget.fk_region
        this.year = this.budget.year
        this.fk_work_category = !this.budget.budget_id ? 0 : this.budget.fk_work_category
        this.amount_kgs = this.budget.amount_kgs
        this.amount_usd = this.budget.amount_usd
        this.version = !this.budget.version ? 0 : this.budget.version
        this.approved = !this.budget.approved ? 0 : this.budget.approved
      }
    },

    methods: {
      onModalClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theBudget = {
          year: this.year,
          fk_region: this.fk_region,
          fk_work_category: this.fk_work_category,
          amount_kgs: this.amount_kgs / 1,
          amount_usd: this.amount_kgs / this.exchange_rate,
          version: this.version + 1,
          approved: 0,
          ...(!this.budget.budget_id ? { created_at: new Date() } : { updated_at: new Date() })
        }

        const action = `${this.budget.budget_id ? 'UPD' : 'ADD'}_BUDGET`
        const payload = this.budget.budget_id ? { id: this.budget.budget_id, theBudget: theBudget } : theBudget
        let errDesc = ''
        try {
          await this.$store.dispatch(action, payload)
        } catch (err) {
          errDesc = err
        }
        this.savedMessage(errDesc, this.$t('budget.amount'), this.amount_kgs)
        this.$emit('saved')
      },
    },
    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      },

      workCategoriesList() {
        return this.$store.state.Dropdowns.work_categories
      },

      categoriesInBudget() {
        return this.$store.getters.categoriesInBudget(this.fk_region, this.year)
      },

      filteredCategories() {
        const res = this.workCategoriesList.filter(item1 =>
          !this.categoriesInBudget.some(item2 => item1.work_category_id === item2.fk_work_category)
        )
        //res.unshift({ work_category_id: 0, description: '' })
        return res
      }
    },

    watch: {
    }
  }
</script>
<style>
.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
